
form.search-input-wrapper{
    @apply flex border border-secondary-100 items-center px-4 rounded-md;
}

form.search-input-wrapper input{
    @apply py-2 pl-px bg-transparent;
}

form.search-input-wrapper input:focus-visible{
    outline: none;
}