@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Avenir Next';
    font-weight: 500;
    src: url(fonts/avenir-next/medium.woff) format("woff");
  }
  @font-face {
    font-family: 'Avenir Next';
    font-weight: 600;
    src: url(fonts/avenir-next/demi.woff) format("woff");
  }
  @font-face {
    font-family: 'Avenir Next';
    font-weight: 700;
    src: url(fonts/avenir-next/bold.woff) format("woff");
  }

  @font-face {
    font-family: 'ITC Franklin Gothic Std';
    font-weight: 400;
    src: url(fonts/franklin-gothic-std/medium-condensed.woff) format("woff");
  }

  *,html{
    box-sizing: border-box;
  }

  body {
    @apply font-avenir font-medium;
    @apply bg-primary-100;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.0625rem;
  }

  p {
    font-size: 1.0625rem;
  }

  .app{
    @apply grid grid-cols-12 h-screen;
    grid-template-rows: 80px 1fr;
  }

  .sidebar{
    @apply col-span-2 bg-primary-600 row-span-full flex flex-col;
  }

  .nav{
    @apply col-span-10 h-full;
  }

  .main{
    @apply col-start-3 col-end-13 overflow-scroll;
  }

  .grid-general{
    @apply grid grid-cols-3 gap-8;
  }

  .circular-progress{
    position: relative;
    @apply text-darkGrey;
  }

  .circular-progress::before, .circular-progress::after{
    @apply absolute top-40 opacity-60;
  }
  .circular-progress::before{
    content: 'Low';
    @apply -left-12;
  }

  .circular-progress::after{
    content: 'High';
    @apply -right-12;
  }

  .note-item{
    position: relative;
  }
  .note-item::before{
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 6px;
    width: 6px;
    background: red;
    border-radius: 50%;
  }

  .form-steps{
    @apply w-1/5 rounded-sm bg-main h-1 focus:outline-none cursor-default;
  }

  .form_divider{
    position: relative;
    @apply uppercase;
  }

  .form_divider::before, .form_divider::after{
    position: absolute;
    content: "";
    background: inherit;
    width: 40%;
    height: 1.5px;
    top: 50%;
    @apply bg-secondary-200;
  }
  .form_divider::before{
    left: 0px;
  }
  .form_divider::after{
    right: 0px;
  }

  .google-button{
    @apply justify-center !important;
  }

  .risk-bg-color{
    background-color: rgba(0, 136, 178, 0.17);
  }

  .profile-heading{
    width: 100%;
    max-width: 90%;
  }
  
}

  
